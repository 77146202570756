import React from "react";
import "./ComingSoonPage.css"; // Make sure to import the CSS file

const ComingSoonPage = () => {
  return (
    <div className="container">
      <div className="text">
        <h1 className="title">Coming Soon</h1>
        <p className="description">We're working on something awesome!</p>
        <h5 className="email">emeymediainc@gmail.com</h5>
      </div>
    </div>
  );
};

export default ComingSoonPage;
